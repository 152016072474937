<template>
  <v-card>
    <v-card-title>
      Purchase Order #
      <v-chip
        class="ml-2"
        small
        color="primary"
      >
        {{ form.po_number }}
      </v-chip>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="savePO"
      >
        <span>Save</span>
      </v-btn>

      <v-btn
        color="primary"
        class="mr-2"
        outlined
        :disabled="generatingPdf"
        @click="generatePdf"
      >
        <v-icon small>
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">Export PDF</span>
      </v-btn>

      <v-btn
        color="primary"
        class="mr-2"
        outlined
        :disabled="generatingPdf"
        @click="generateNTP"
      >
        <v-icon small>
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">NTP</span>
      </v-btn>

      <v-btn
        color="primary"
        class="mr-2"
        outlined
        :disabled="generatingPdf"
        @click="generateNOA"
      >
        <v-icon small>
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">NOA</span>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row v-if="form.rfq">
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="form.rfq.pr_number"
            label="PR No"
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="form.rfq.quotation_number"
            label="Quotation No"
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="form.rfq.office_name"
            label="Office"
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="form.supplier.supplier_name"
            label="Supplier"
            dense
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-menu
              v-model="poDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.po_date"
                  readonly
                  label="PO Date"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.po_date"
                color="primary"
                @input="poDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="form.payment_term"
              :items="paymentTerms"
              hide-details="auto"
              label="Payment Term"
              outlined
              dense
              hide-no-data
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-autocomplete
              v-model="form.delivery_term"
              :items="deliveryTerms"
              hide-details="auto"
              label="Delivery Term"
              outlined
              dense
              hide-no-data
            >
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="form.procurement_mode"
              :items="procurementModes"
              hide-details="auto"
              label="Mode of Procurement"
              outlined
              dense
              hide-no-data
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-menu
              v-model="deliveryDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.delivery_date"
                  readonly
                  label="Delivery Date"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.delivery_date"
                color="primary"
                @input="deliveryDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.delivery_place"
              outlined
              dense
              label="Delivery Place"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="form.remarks"
              auto-grow
              rows="1"
              outlined
              dense
              label="Purpose/Remarks"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-subtitle> <strong>Tracing Matrix </strong></v-card-subtitle>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="poServedDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.po_served_date"
                  readonly
                  label="PO Served"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.po_served_date"
                color="primary"
                @input="poServedDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="poInspectionDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.po_inspection_date"
                  readonly
                  label="Inspection Date"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.po_inspection_date"
                color="primary"
                @input="poInspectionDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="poForPaymentDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.po_for_payment_date"
                  readonly
                  label="For Payment"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.po_for_payment_date"
                color="primary"
                @input="poForPaymentDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="poForChequeDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.po_for_cheque_date"
                  readonly
                  label="For Cheque"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.po_for_cheque_date"
                color="primary"
                @input="poForChequeDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-tabs
      v-model="tab"
      fixed-tabs
    >
      <v-tab>
        <h4 v-if="form.items">
          Items ({{ form.items.length }})
        </h4>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Stock No
                    </th>
                    <th class="text-left">
                      Unit
                    </th>
                    <th class="text-left">
                      Description
                    </th>
                    <th class="text-left">
                      Model/Brand Offered
                    </th>
                    <th class="text-left">
                      Qty
                    </th>
                    <th class="text-left">
                      Unit Cost
                    </th>
                    <th class="text-left">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in form.items"
                    :key="key"
                  >
                    <td style="width: 2%">
                      {{ key + 1 }}
                    </td>
                    <td style="width: 10%">
                      {{ item.unit }}
                    </td>
                    <td>
                      <p class="pt-2">
                        <strong>{{ item.item.description }}</strong>
                      </p>
                      <p>{{ item.specification }}</p>
                    </td>
                    <td style="width: 10%">
                      {{ item.brand }}
                    </td>
                    <td style="width: 10%">
                      {{ item.quantity }}
                    </td>
                    <td style="width: 10%">
                      {{ (1 * item.unit_cost).toLocaleString("en-US") }}
                    </td>
                    <td style="width: 10%">
                      {{ (item.quantity * item.unit_cost).toLocaleString("en-US") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <snackbar
      ref="snackbar"
      :message="message"
    ></snackbar>
  </v-card>
</template>

<script>
import axios from '@axios'
import {
  mdiCalendarCheckOutline, mdiClose,
  mdiCogOutline, mdiExportVariant, mdiPlus, mdiSpeedometer,
  mdiStarShooting,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import Snackbar from '../Snackbar.vue'
import useUIResolver from '../useUIResolver'
import usePurchaseOrders from './usePurchaseOrders'

const formInit = () => ({
  id: null,
  supplier_id: null,
  rfq_id: null,
  delivery_date: null,
  delivery_place: null,
  payment_term: 'Regular Payment',
  delivery_upon_approved: 1,
  delivery_term: null,
  procurement_mode: null,
  remarks: null,
  po_for_cheque_date: null,
  po_for_payment_date: null,
  po_inspection_date: null,
  po_served_date: null,
  po_date: null,
})

export default {
  components: { Snackbar },
  setup() {
    const saving = ref(false)
    const generatingPdf = ref(false)
    const snackbar = ref(null)
    const message = ref('')
    const suppliers = ref([])
    const deliveryDatePicker = ref(false)
    const poForChequeDatePicker = ref(false)
    const poForPaymentDatePicker = ref(false)
    const poInspectionDatePicker = ref(false)
    const poServedDatePicker = ref(false)
    const poDatePicker = ref(false)
    const tab = ref(null)
    const form = ref(formInit())
    const poEditorDialog = ref(null)
    const poDialog = ref(null)
    const errors = ref([])

    const { purchaseOrder, getPurchaseOrder, loading: loadingPurchaseOrder } = usePurchaseOrders()

    const { deliveryTerms, paymentTerms, procurementModes } = useUIResolver()

    const generatePdf = () => {
      generatingPdf.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-po/${router.currentRoute.params.id}`
      generatingPdf.value = false
    }

    const generateNTP = () => {
      generatingPdf.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-ntp/${router.currentRoute.params.id}`
      generatingPdf.value = false
    }

    const generateNOA = () => {
      generatingPdf.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-noa/${router.currentRoute.params.id}`
      generatingPdf.value = false
    }

    const savePO = () => {
      saving.value = true
      axios.put(`/purchase-orders/${router.currentRoute.params.id}`, form.value).then(r => {
        message.value = 'Purchase order saved.'
        snackbar.value.visible = true
        saving.value = false
      })
    }

    onMounted(() => {
      if (router.currentRoute.params.id) {
        saving.value = true
        getPurchaseOrder(router.currentRoute.params.id).then(r => {
          form.value = r.data
          saving.value = false
        })
      }
    })

    return {
      message,
      snackbar,
      saving,
      generatingPdf,

      poEditorDialog,
      poDialog,
      savePO,

      generatePdf,

      generateNTP,

      generateNOA,

      errors,
      deliveryTerms,
      paymentTerms,
      procurementModes,

      tab,
      suppliers,
      form,
      deliveryDatePicker,
      poDatePicker,
      poServedDatePicker,
      poForChequeDatePicker,
      poForPaymentDatePicker,
      poInspectionDatePicker,

      purchaseOrder,
      getPurchaseOrder,
      loadingPurchaseOrder,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiExportVariant,
        mdiSpeedometer,
        mdiStarShooting,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>
